import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import {
    Container,
    ContentContainer,
    FooterTop,
    FooterMenu,
    FooterMenuItem,
    FooterBottom,
    FooterPurchases,
    FooterPurchasesBtn
} from './Footer.styled';
import useTranslation from '@/hooks/useTranslation';
import { FooterI, FooterPurchasesI } from '@/interfaces';
import Only from '@/icons/made-only-footer.svg';
import FooterContacts from '@/components/blocks/FooterContacts/FooterContacts';
import MenuList from '@/components/blocks/MenuList/MenuList';
import { sendMetrik } from '@/helpers';
import { useRouter } from 'next/router';

const Spoiler = dynamic(() => import('@/components/blocks/Spoiler/Spoiler'));

const ModalWrapper = dynamic(() => import('../../common/ModalWrapper/ModalWrapper'));
const CallBackForm = dynamic(() => import('../../common/CallBackForm/CallBackForm'));
const AskQuestion = dynamic(() => import('../../common/AskQuestion/AskQuestion'));
const ServicesForm = dynamic(() => import('../../blocks/ServicesForm/ServicesForm'));
const ServicesFormWithOrgName = dynamic(() => import('../../blocks/ServicesFormWithOrgName/ServicesFormWithOrgName'));
const NDSForm = dynamic(() => import('../../blocks/NDSForm/NDSForm'));

import ResizeObserver from 'resize-observer-polyfill';
import Link from 'next/link';
import useIsMatches from '@/hooks/useIsMatches';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { setIsFooterVisible } from '@/redux/footer/footerSlice';
import { useServicePage } from '@/hooks/useServicePage';
import SubscribeForm from '@/components/blocks/SubscribeForm/SubscribeForm';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';

interface FooterProps {
    propsFooterForm?: string;
    modalTitle?: string;
    modalID?: string;
}

interface FooterPurchasesSectionProps {
    purchases: FooterPurchasesI[];
}

const Footer: React.FC<FooterProps> = ({ propsFooterForm, modalTitle, modalID }) => {
    const { t } = useTranslation('common');
    const [footer] = useState<FooterI>(() => t('footer', { returnObjects: true }));
    const [heightMenu, setHeightMenu] = useState(0);
    const [currentYear] = useState<number>(() => new Date().getFullYear());
    const menuRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useIsMatches('md');
    const servicePage = useServicePage();
    const { ref, inView } = useInView();
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsFooterVisible(inView));
    }, [inView]);

    useEffect(() => {
        let resizeObserver: ResizeObserver;

        const resizeHandler = () => {
            if (menuRef.current) {
                setHeightMenu(menuRef.current.clientHeight);
            }
        };
        if (menuRef.current) {
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(document.documentElement);
            resizeHandler();
        }

        return () => resizeObserver?.disconnect();
    }, [menuRef]);

    const FooterPurchasesSection = ({ purchases }: FooterPurchasesSectionProps) => (
        <FooterPurchases>
            {purchases.map(({ href, text }, i) => {
                const isActive = pathname === href;
                return (
                    <Link key={i} href={href} passHref prefetch={false}>
                        <FooterPurchasesBtn disabled={isActive} onClick={() => sendMetrik('footer-click-subcribe', 'footer', {})}>
                            {text}
                        </FooterPurchasesBtn>
                    </Link>
                );
            })}
        </FooterPurchases>
    );

    return (
        <Container ref={ref}>
            <ContentContainer>
                <FooterTop heightMenu={heightMenu}>
                    <FooterContacts {...footer} />
                    <FooterMenu ref={menuRef}>
                        {isMobile
                            ? footer.menu?.map((item, index) => (
                                  <FooterMenuItem key={index}>
                                      {item.id === 'education' && footer.purchases && (
                                          <FooterPurchasesSection purchases={footer.purchases} />
                                      )}
                                      <Spoiler arrowBtn key={index} header={<span> {item.text} </span>} headerBtn={false}>
                                          {item.subLinks &&
                                              MenuList([
                                                  {
                                                      text: item.text,
                                                      href: item.href
                                                  },
                                                  ...item.subLinks
                                              ])}
                                      </Spoiler>
                                  </FooterMenuItem>
                              ))
                            : footer.menu?.map((item, index) => (
                                  <FooterMenuItem key={index}>
                                      <p>{item.href ? PotentiallyLink(item) : item.text}</p>
                                      {item.subLinks && MenuList(item.subLinks)}
                                      {item.id === 'services' && footer.purchases && (
                                          <FooterPurchasesSection purchases={footer.purchases} />
                                      )}
                                  </FooterMenuItem>
                              ))}
                    </FooterMenu>
                </FooterTop>

                <FooterBottom>
                    {footer.label && <p dangerouslySetInnerHTML={{ __html: `&copy; ${currentYear} ${footer.label}` }} />}
                    <a href={'https://onlydigital.ru'} target={'_blank'} rel={'noreferrer'}>
                        <Only />
                    </a>
                </FooterBottom>

                <ModalWrapper id={'callBackForm'} title={t('callBackForm.title')} description={t('callBackForm.description')}>
                    <CallBackForm formId={'help'} />
                </ModalWrapper>

                <ModalWrapper
                    id={'askQuestionForm'}
                    title={t('askQuestionForm.title')}
                    description={t('askQuestionForm.description')}
                    onMaxWidth
                >
                    <AskQuestion formId={'help'} />
                </ModalWrapper>

                <ModalWrapper
                    id={'subscribeForm'}
                    title={t('subscribeForm.title')}
                    description={t('subscribeForm.description')}
                    imageRight={t('subscribeForm.imageRight', { returnObjects: true })}
                >
                    <SubscribeForm confirmMessage={t('confirm5', { returnObjects: true })} formId="help" />
                </ModalWrapper>

                <ModalWrapper
                    description={t('marketCenter.description')}
                    id={'ApplicationForm3MarketCentre'}
                    title={t('ServicesForm.title2', { returnObjects: true })}
                >
                    <ServicesForm
                        {...t('ServicesForm', { returnObjects: true })}
                        formId={'ApplicationForm3MarketCentre'}
                        landing_id={propsFooterForm}
                    />
                </ModalWrapper>

                <ModalWrapper
                    description={t('manageDogovors.description')}
                    id={'ApplicationForm3ManageDogovor'}
                    title={t('ServicesForm.title2', { returnObjects: true })}
                >
                    <ServicesForm
                        {...t('ServicesForm', { returnObjects: true })}
                        formId={'ApplicationForm3ManageDogovor'}
                        landing_id={propsFooterForm}
                    />
                </ModalWrapper>

                <ModalWrapper
                    description={t('nsi.description')}
                    id={'ApplicationForm3NSI'}
                    title={t('ServicesForm.title2', { returnObjects: true })}
                >
                    <ServicesForm
                        {...t('ServicesForm', { returnObjects: true })}
                        formId={'ApplicationForm3NSI'}
                        landing_id={propsFooterForm}
                    />
                </ModalWrapper>

                <ModalWrapper
                    description={modalTitle ? modalTitle : servicePage.titles.title}
                    id={'ApplicationForm3'}
                    title={t('ServicesForm.title2', { returnObjects: true })}
                >
                    <ServicesForm
                        {...t('ServicesForm', { returnObjects: true })}
                        formId={modalID ?? 'callback_loyalty'}
                        landing_id={propsFooterForm}
                    />
                </ModalWrapper>
                <ModalWrapper
                    description={modalTitle ? modalTitle : servicePage.titles.title}
                    id={'ServicesFormWithTitle'}
                    title={t('ServicesForm.title2', { returnObjects: true })}
                >
                    <ServicesFormWithOrgName
                        {...t('ServicesForm', { returnObjects: true })}
                        formId={modalID ?? 'callback_loyalty'}
                        landing_id={propsFooterForm}
                    />
                </ModalWrapper>
                <ModalWrapper {...t('ndsModal', { returnObjects: true })} id={'NDSForm'}>
                    <NDSForm formId={'e-signature_non-residents'} />
                </ModalWrapper>
            </ContentContainer>
        </Container>
    );
};

export default Footer;
