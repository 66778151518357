import React, { useCallback } from 'react';
import { Container, ModalBtnContainer } from './RectLink.styled';
import Link from 'next/link';
import ModalBtn from '../ModalBtn/ModalBtn';
import { IMetrika, IMetrikaNewHandler, ModalBtnI } from '@/interfaces';
import { useRouter } from 'next/router';
import { METRIKS_SAMPLES, sendMetriksConstructor } from '@/metriks/metriksSample';
import { redirectToStand } from '@/helpers/redirectToStand';
import { useCurrentUrl } from '@/hooks/useCurrentUrl';

export type RectLinkViewType = 'fill-white' | 'fill-black' | 'fill-blue' | 'fill-bg-dark' | 'stroke';

export interface RectLinkProps extends IMetrika, IMetrikaNewHandler {
    text?: string;
    href?: string;
    isForeign?: boolean;
    className?: string;
    type?: RectLinkViewType;
    id?: string;
    altDescription?: ModalBtnI['altDescription'];
    clickOnButton?: React.MouseEventHandler;
    scrollToSelector?: string;
    asLeadCourseId?: boolean;
    withoutRedirect?: boolean;
}

const RectLink: React.FC<RectLinkProps> = ({
    text,
    href,
    isForeign,
    className,
    type = 'stroke',
    id,
    altDescription,
    metrika,
    clickOnButton,
    scrollToSelector,
    newMetrikaHandler,
    asLeadCourseId,
    withoutRedirect
}) => {
    const { pathname, query } = useRouter();

    const scrollToHandler = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            const neededElement = scrollToSelector && document?.querySelector(scrollToSelector);
            if (neededElement) {
                const yOffset = -75;
                const y = neededElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }
            asLeadCourseId &&
                METRIKS_SAMPLES[pathname]?.leadButtonCourseId &&
                sendMetriksConstructor(pathname, 'leadButtonCourseId', [{ neededKey: 'click-registrate', newValue: query.id as string }]);
        },
        [scrollToSelector]
    );
    const currentUrl = useCurrentUrl();

    return href ? (
        <Link href={withoutRedirect ? href : redirectToStand(href, currentUrl)} passHref prefetch={false}>
            <Container
                type={type}
                as={'a'}
                className={className}
                target={isForeign ? '_blank' : undefined}
                onClick={metrika || clickOnButton || newMetrikaHandler}
            >
                <span>{text}</span>
            </Container>
        </Link>
    ) : id ? (
        <ModalBtnContainer type={type} onClick={metrika || newMetrikaHandler}>
            <ModalBtn id={id} altDescription={altDescription}>
                {<span>{text}</span>}
            </ModalBtn>
        </ModalBtnContainer>
    ) : (
        <Container type={type} className={className} onClick={scrollToHandler}>
            <span>{text}</span>
        </Container>
    );
};

export default RectLink;
