import breakpoints from '@/style/breakpoints';
import { IImageApi, IOurCustomerItem, IYTVideo } from '@/interfaces';
import dayjs from 'dayjs';

export const matchMediaBreakpoint = (breakpoint: keyof typeof breakpoints) =>
    window.matchMedia(`(max-width: ${breakpoints[breakpoint] - 1}px)`);

/**
 * @returns Возможность touch-управления на устройстве
 */
export const isTouchDevice = (): boolean => {
    return !!('ontouchstart' in window || navigator.maxTouchPoints);
};

export const getDesiredDate = (offsetZone = 3, locale = 'ru') => {
    const currDate = new Date();
    const currDateMs = currDate.getTime();
    const currTimeZoneMs = currDate.getTimezoneOffset() * 60 * 1000;
    const desiredTimeMS = currDateMs + currTimeZoneMs + offsetZone * 60 * 60 * 1000;
    const desiredDate = new Date(desiredTimeMS);
    return desiredDate.toLocaleDateString(locale);
};

export const getEventDate = (value: string | undefined) => {
    if (!!value) {
        return dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
    }
    return '';
};

export const compareEventDateTimeWithNow = (date: string) =>
    dayjs(dayjs(date, 'DD.MM.YYYY h:mm:ss')).diff(dayjs(new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' })), 'minute');

export const truncateText = (text: string, maxSymbols: number) => {
    return text?.length > maxSymbols ? `${text.substring(0, maxSymbols).trim()}...` : text;
};

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const getResizeObserverWidthChangeHandler = (callback: () => void) => {
    let prevWidth = 0;

    return (entries?: ResizeObserverEntry[]) => {
        if (!entries) callback();

        entries?.forEach((entry) => {
            if (prevWidth !== entry.contentRect.width) {
                callback();
            }
            prevWidth = entry.contentRect.width;
        });
    };
};

export const doesSupportPassiveEventListeners = () => {
    let supportsPassive = false;
    if (typeof window === 'undefined') return supportsPassive;
    try {
        let opts = Object.defineProperty({}, 'passive', {
            get: function () {
                supportsPassive = true;
            }
        });
        const callback = () => {};
        window.addEventListener('testPassive', callback, opts);
        window.removeEventListener('testPassive', callback, opts);
    } catch (e) {}
    return supportsPassive;
};

export const YM_ID = process.env.YM_ID;
/* YM_ID =
ТЗ: 47749948;
Ольга: 91302529;
Антонов.М.: 91415546;
Ивашин.М.: 91466243;
Мищенко.В.: 91478485;
*/
export const GM_ID = `${process.env.GM_ID}`;

const BANNED_SYMBOLS = ['&nbsp;', '&laquo;', '&raquo;', '&mdash;', '</br>', '<br>', '<br/>'];
const CLEAR_REGEXP = new RegExp(BANNED_SYMBOLS.join('|'), 'g');

export const clearFromNBSP = (text: string | undefined) => {
    if (text) return text.replace(CLEAR_REGEXP, ' ');
    else return null;
};

const ObjectPropertiesStringify = (obj: { [key: string]: any }) => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'number') {
            obj[key] += '';
        }
    });
    return obj;
};

const toArrayOfObjects = (obj: { [key: string]: any }) => {
    if (!Object.keys(obj).length) return [{}];
    return Object.entries(obj).map((entry) => {
        return { [entry[0]]: entry[1] };
    });
};

export const sendMetrik = (eventName?: string, eventCategory?: string, eventAction?: object, GA_EventAction?: string, onlyYm = false) => {
    if (eventAction) eventAction = ObjectPropertiesStringify(eventAction);
    const _type = 'reachGoal';
    const _eventLabel = window.location.href;
    // const _parameters = { [_eventLabel]: eventAction };
    if (eventName && eventCategory && eventAction) {
        const _parameters = { [_eventLabel]: toArrayOfObjects(eventAction) };
        // @ts-ignore
        window.ym(YM_ID, _type, eventName);
        // @ts-ignore
        window.ym(YM_ID, 'params', _parameters);
        metriksDebugger('Ya Params:', _parameters);
        pushDataLayer(eventName, eventCategory, eventAction, GA_EventAction);
    } else if (eventName && onlyYm) {
        // @ts-ignore
        window.ym(YM_ID, _type, eventName);
    }
};

const pushDataLayer = (eventName: string, eventCategory: string, eventAction: object, GA_EventAction?: string) => {
    const eventLabel = window.location.href;

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    const tags = {
        event: eventName,
        eventCategory: eventCategory,
        eventAction: GA_EventAction ? GA_EventAction : eventAction,
        eventLabel: eventLabel,
        ...eventAction
    };
    // @ts-ignore
    window.dataLayer.push(tags);
    metriksDebugger('GTM Data Layer:', tags);
};

const metriksDebugger = (msg: string, data: object) => {
    if (window.location.host === 'localhost:3000') {
        console.log(msg, data);
    }
};

/**
 * @description Создает массив слайдов из массива видео YouTube, для отображения в слайдере
 */
export const getYoutubeVideoSlides = (youtubeVideosArr: IYTVideo[]) => {
    if (!youtubeVideosArr.length) return { items: [] };

    const slidesArr = youtubeVideosArr?.map(({ title, thumbnails, youtube_id }) => {
        return {
            title: title,
            image: thumbnails?.standard?.url || '',
            link: {
                href: `https://youtu.be/watch?v=${youtube_id}`,
                text: '',
                isForeign: true
            }
        };
    });

    return { items: slidesArr };
};

export const getPicture = (item: any, alt = '') => {
    const picture = {
        src: item?.mobile || item?.tablet || item?.desktop,
        alt: alt
    } as {
        src: string;
        sources?: {
            src: string;
            breakpoint: number;
        }[];
    };
    if (item?.desktop || item?.tablet) {
        picture['sources'] = [];
        if (item?.desktop) {
            picture['sources'].push({
                src: item?.desktop,
                breakpoint: 1280
            });
        }
        if (item?.tablet) {
            picture['sources'].push({
                src: item?.tablet,
                breakpoint: 768
            });
        }
    }
    return picture;
};

export const getSrc = (image: string | IImageApi) => {
    return typeof image === 'object'
        ? image?.desktop?.length != undefined
            ? image?.desktop
            : image?.tablet?.length != undefined
            ? image?.tablet
            : image?.mobile?.length != undefined
            ? image?.mobile
            : ''
        : image;
};

export const createItemCustomers = (item: IOurCustomerItem, locale: string, path = '') => {
    const procedures = typeof item.proceduresCounter !== 'undefined' && item.proceduresCounter >= 0 ? `: ${item.proceduresCounter}` : '';

    return {
        title: item.name,
        image: 'logo' in item && getSrc(item.logo),
        link: {
            text: locale === 'ru' ? `Закупочные процедуры${procedures}` : `Procurement procedures${procedures}`,
            href: `${path}/${item.slug}`
        }
    };
};

export const convertCustomerInfo = (data: any) => {
    const obj = {
        alias: data.alias,
        title: data.name,
        leadImg: {
            title: data.name,
            background: data?.background ? getPicture(data?.background) : '',
            widgets: []
        },
        bannerText: {
            title: data.name,
            text: data.description
        }
    } as any;

    if (!!data.link_text.length && data.link) {
        obj.leadImg.widgets.push({
            type: 'link',
            href: data.link,
            text: data.link_text,
            isForeign: true
        });
    }

    if (data.document) {
        obj.leadImg.widgets.push({
            type: 'file',
            href: data.document.path,
            text: data.document.name || data.document.original_name,
            isForeign: true,
            extension: data.document.extension,
            size: data.document.size
        });
    }

    return obj;
};

export const createItemPostSlide = (item: any) => ({
    title: item.title,
    date: item.date,
    image: 'image' in item && getSrc(item.image),
    bgColor: 'gray2',
    link: {
        text: '',
        href: `/education/${'audience' in item && item.audience === 'supplier' ? 'expert-articles-supliers' : 'expert-articles'}/${
            item.slug
        }`
    },
    linkMore: false,
    fullWidthTitle: true,
    textColor: item.image && item.is_dark_image ? 'white' : 'black',
    dateColor: item.image && item.is_dark_image ? 'white' : 'black',
    type: {
        name: 'news'
    },
    tags: item.tags,
    description: item.preview_text
});

export const declineWords = (number: number, words: string[]) => {
    const numbersUpToFive = number % 10 < 5 ? number % 10 : 5;
    const isPlural = number % 100 > 4 && number % 100 < 20;

    return words[isPlural ? 2 : [2, 0, 1, 1, 1, 2][numbersUpToFive]];
};

export const getDeclineTextProcurementFound = (total: number, locale?: string) => {
    const wordsPurchase = {
        ru: ['закупка', 'закупки', 'закупок'],
        en: ['procurement', 'procurements', 'procurement']
    };

    const wordsFound = {
        ru: ['найдена', 'найдены', 'найдено'],
        en: ['found', 'found', 'found']
    };

    return `${declineWords(total, wordsPurchase[locale === 'ru' ? 'ru' : 'en'])} ${declineWords(
        total,
        wordsFound[locale === 'ru' ? 'ru' : 'en']
    )}`;
};

export const getGridListAngles = (index: number) => {
    const anglesMap = [
        ['bottom-right'], // 0
        ['bottom-left', 'bottom-right'], // 1
        ['bottom-left'], // 2
        // Закономерности с шагом в 3.
        ['top-right', 'bottom-right'], // 3, 6, 9 ...
        ['top-left', 'top-right', 'bottom-right', 'bottom-left'], // 4, 7, 10 ...
        ['top-left', 'bottom-left'] // 5, 8, 11 ...
    ];
    if (index < anglesMap.length) {
        return anglesMap[index];
    }
    return anglesMap[(index - 3) % anglesMap.length];
};
