//Базовая палитра
const basePalette = {
    white: '#FFFFFF',
    black: '#000000',

    //DeepBlue
    'DeepBlue/100': '#c0c1c4',
    'DeepBlue/200': '#A0A3A9',
    'DeepBlue/300': '#4A515A',
    'DeepBlue/400': '#27333E',
    'DeepBlue/500': '#1F2C37',
    'DeepBlue/600': '#192834',
    'DeepBlue/700': '#0B1F29',
    'DeepBlue/800': '#091921',
    'DeepBlue/900': '#000E16',
    'DeepBlue/1000': '#00070A',

    //Blue
    'Blue/100': '#E6F4FC',
    'Blue/200': '#CCE8F8',
    'Blue/300': '#99D2F2',
    'Blue/400': '#66BBEB',
    'Blue/500': '#33A5E5',
    'Blue/600': '#008EDE',
    'Blue/700': '#006EB2',
    'Blue/800': '#005285',
    'Blue/900': '#002B47',
    'Blue/1000': '#001A2C',

    //Gray
    'Gray/100': '#F1F5F9',
    'Gray/200': '#D9DDE0',
    'Gray/300': '#C1C4C7',
    'Gray/400': '#A9ACAE',
    'Gray/500': '#919395',
    'Gray/600': '#797b7d',
    'Gray/700': '#606264',
    'Gray/800': '#48494B',
    'Gray/900': '#2B2B2B',
    'Gray/1000': '#232424',

    //BlueGray
    'BlueGray/100': '#F1F5F9',
    'BlueGray/200': '#DCE5ED',
    'BlueGray/300': '#CBD7E2',
    'BlueGray/400': '#B9C8D4',
    'BlueGray/500': '#AAB9C5',
    'BlueGray/600': '#9AAAB6',
    'BlueGray/700': '#8A9CA8',
    'BlueGray/800': '#7A8C99',
    'BlueGray/900': '#6C7D89',
    'BlueGray/1000': '#5E6D78',

    //Marine
    'Marine/100': '#BFD3D9',
    'Marine/200': '#ABC6CF',
    'Marine/300': '#96BAC5',
    'Marine/400': '#80AEBC',
    'Marine/500': '#6AA2B4',
    'Marine/600': '#5296AB',
    'Marine/700': '#47879B',
    'Marine/800': '#3B7587',
    'Marine/900': '#306373',
    'Marine/1000': '#26515F',

    //Green
    'Green/600': '#3AA949',

    //Red
    'Red/600': '#F13941',

    //Yellow
    'Yellow/100': '#FFFEC2',
    'Yellow/200': '#FFFA99',
    'Yellow/300': '#FFF266',
    'Yellow/400': '#FFEA47',
    'Yellow/500': '#FFDF29',
    'Yellow/600': '#FFD200',
    'Yellow/700': '#F0B800',
    'Yellow/800': '#CC9600',
    'Yellow/900': '#8A6000',
    'Yellow/1000': '#754C00'
};

const colors = {
    ...basePalette,

    //Функциональная палитра
    //Content
    'Content-01': basePalette['white'],
    'Content-02': basePalette['BlueGray/200'],
    'Content-03': basePalette['Marine/600'],
    'Content-04': basePalette['DeepBlue/400'],
    'Content-01-hover': basePalette['BlueGray/200'],
    'Content-02-hover': basePalette['BlueGray/300'],
    'Content-03-hover': basePalette['Marine/700'],
    'Content-04-hover': basePalette['DeepBlue/300'],

    //Bg
    'Bg-Light': basePalette['Gray/100'],
    'Bg-Dark': basePalette['DeepBlue/900'],

    //Text
    'text-primary': basePalette['DeepBlue/400'],
    'text-secondary': basePalette['DeepBlue/300'],
    'text-tertiary': basePalette['DeepBlue/200'],
    'text-quaternary': basePalette['DeepBlue/100'],
    'text-oncolor-primary': basePalette['white'],
    'text-oncolor-secondary': basePalette['white'], // opacity - 0.8
    'text-oncolor-tertiary': basePalette['white'], // opacity - 0.6
    'text-oncolor-quaternary': basePalette['white'], // opacity - 0.4

    //Brand
    brandPrimary: basePalette['Blue/600'],
    'brandPrimary-hover': basePalette['Blue/700'],
    brandRosneft: basePalette['Yellow/600'],
    'brandRosneft-hover': basePalette['Yellow/700'],

    //UI
    'ui-label-default': basePalette['BlueGray/800'],
    'ui-text-default': basePalette['DeepBlue/400'],
    'ui-icon-default': basePalette['BlueGray/1000'],
    'ui-bg': basePalette['BlueGray/100'],
    'ui-bg-hover': basePalette['BlueGray/200'],

    //Divider
    'divider-onwhite': basePalette['BlueGray/200'],
    'divider-onbg': basePalette['BlueGray/400'],
    'divider-ondark': basePalette['white'], // opacity - 0.2

    //Support
    Success: basePalette['Green/600'],
    Error: basePalette['Red/600'],
    Warning: basePalette['Yellow/600'],

    //Link
    'link-default': basePalette['DeepBlue/400'],
    'link-hover': basePalette['DeepBlue/300'],
    'link-accent-default': basePalette['Blue/600'],
    'link-accent-hover': basePalette['Blue/700'],

    white: '#FFFFFF',
    black: '#000000',
    black2: '#19191A',
    black3: '#1c293a',
    black4: '#111111',
    blue: '#008EDE',
    blue2: '#008AAB',
    blue3: '#99B6D8',
    blue4: '#448CCB',
    blue5: '#223C6F',
    blue6: '#047CC0',
    gray: '#ECF1F7',
    gray2: '#F6F9FC',
    gray3: '#F4F9FE',
    gray4: '#C9C8C7',
    gray5: '#A8A8A8',
    gray6: '#ECF1F6',
    red: '#FF3030',
    red2: '#E45555',
    maskColor1: '#ecf1f6',
    yellow: '#FFD200',
    orange: '#F47500',
    yellow2: '#FED230',
    yellow3: '#FFCF52',
    orange2: '#E9862A',
    darkBlue: '#004781',
    green: '#28B056',
    grayish: '#808080',
    turquoise: '#2B9EB5',
    turquoise2: '#096679',

    //цвета редизайна (старые)
    'light-blue-background': '#f1f5f9',

    brandSale: 'linear-gradient(189deg, rgba(207, 223, 237, 0.92) 0%, #e2ecf3 100%);'
};

export type Colors = keyof typeof colors;

type ColorsKeys = {
    [key in Colors]: key;
};

export type BasicBackgrounds = keyof Pick<ColorsKeys, 'white' | 'gray2' | 'Bg-Light'>;
export type TextColors = keyof Pick<ColorsKeys, 'white' | 'black'>;

export default colors;
