import styled, { css } from 'styled-components';
import { offset, mediaBreakpointUp, font, color } from '@/style/mixins';
import { BaseOffsetsUI } from '@/interfaces';
import { Colors } from '@/style/colors';
import variables from '@/style/variables';

export const Container = styled.div<BaseOffsetsUI>`
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('main', 'padding-top'))};
    ${({ bottomOffset }) => (bottomOffset ? offset(bottomOffset, 'padding-bottom') : offset('main', 'padding-bottom'))};

    position: relative;
    margin: 0 20px;

    ${mediaBreakpointUp('xl')} {
        margin: 0;
    }
`;

export const DocumentsTitle = styled.p`
    ${font('t1-body-exb')};
    ${offset('xs', 'padding-bottom')};

    ${mediaBreakpointUp('xl')} {
        grid-column: 1/4;
    }
`;

export const DocumentsGroup = styled.div`
    position: relative;

    &:not(:first-of-type) {
        ${offset('l', 'margin-top')};
    }
`;

export const DocumentsList = styled.div<{ grid?: boolean }>`
    display: grid;
    ${offset('xxs', 'gap')};

    ${({ grid }) =>
        grid &&
        css`
            ${offset('xs', 'gap')};

            ${mediaBreakpointUp('xl')} {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        `}
`;

export const DocumentsWrapper = styled.div<{ severalDoc?: boolean; bgColor?: Colors }>`
    position: relative;
    background-color: ${({ bgColor }) => bgColor && color(bgColor)};
    border-radius: ${({ bgColor }) => bgColor && variables.corners.bigCard};

    ${({ bgColor }) =>
        bgColor &&
        css`
            padding: 24px 20px;

            ${mediaBreakpointUp('md')} {
                padding: 32px 24px;
            }
            ${mediaBreakpointUp('xl')} {
                padding: 40px 32px;
            }
        `}

    ${({ severalDoc }) =>
        severalDoc &&
        css`
            display: grid;
            ${offset('xs', 'gap')};

            ${mediaBreakpointUp('xl')} {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        `}
`;
