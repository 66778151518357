import styled from 'styled-components';
import { color, font, mediaBreakpointUp, allBreakpointValue, hover, sideOffsets } from '@/style/mixins';

import { Container as FooterTechSupport } from '@/components/blocks/FooterTechSupport/FooterTechSupport.styled';
import { Container as MenuList } from '@/components/blocks/MenuList/MenuList.styled';
import { Container as Spoiler, SpoilerHead } from '@/components/blocks/Spoiler/Spoiler.styled';

export const Container = styled.footer`
    width: 100%;
    overflow: hidden;
    margin-top: 30px;
    padding: 30px 20px;
    color: ${color('white')};
    background-color: ${color('Bg-Dark')};
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    ${mediaBreakpointUp('md')} {
        margin-top: 50px;
        padding: 50px 20px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 60px;
        padding: 60px ${sideOffsets()};
    }
`;

export const ContentContainer = styled.div`
    margin: 0 auto;
    max-width: 1200px;
`;

export const FooterTop = styled.div<{ heightMenu: number }>`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    ${mediaBreakpointUp('md')} {
        gap: 54px;
    }
    ${mediaBreakpointUp('xl')} {
        gap: 63px;
        grid-template-columns: 390px 1fr;
    }
`;

export const FooterMenu = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    ${mediaBreakpointUp('md')} {
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
    }
    ${mediaBreakpointUp('xl')} {
        gap: 60px;
    }
`;

export const FooterMenuItem = styled.div`
    position: relative;
    padding: 16px 0;

    ${mediaBreakpointUp('md')} {
        padding: 0;
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${color('white', 0.3)};

        ${mediaBreakpointUp('md')} {
            border-bottom: none;
        }
    }

    p {
        ${font('text3-sb')};
        white-space: nowrap;
        text-transform: uppercase;
    }

    ul {
        padding: 0;
        margin-bottom: 0;
        margin-top: 16px;

        ${mediaBreakpointUp('md')} {
            margin-top: 24px;
        }

        li {
            list-style: none;
            ${font('text3-r')};

            &:not(:last-child) {
                margin-bottom: 12px;

                ${mediaBreakpointUp('md')} {
                    margin-bottom: 16px;
                }
                ${mediaBreakpointUp('xl')} {
                    margin-bottom: 8px;
                }
            }

            a,
            button {
                display: flex;
                align-items: center;
                gap: 12px;
                color: ${color('white', 0.6)};
                transition: color 0.3s ease-in-out;
                ${hover(`color: ${color('white', 0.8)};`)};

                svg {
                    width: 11px;
                    height: 11px;
                    fill: currentColor;
                }
            }
        }
        ${Spoiler} {
            ${SpoilerHead} {
                span {
                    text-transform: none;
                }
            }
        }
    }

    ${Spoiler} {
        ${SpoilerHead} {
            span {
                ${font('text3-sb')};
                text-transform: uppercase;
            }
        }
        ${Spoiler} {
            ${SpoilerHead} {
                span {
                    text-transform: none;
                }
            }
        }
    }

    ${FooterTechSupport} {
        ${mediaBreakpointUp('md')} {
            margin-top: 32px;
        }
        ${mediaBreakpointUp('xl')} {
            margin-top: 40px;
        }

        ul {
            padding: 0;
            margin-bottom: 0;
            margin-top: 16px;

            ${mediaBreakpointUp('md')} {
                margin-top: 24px;
            }

            li {
                list-style: none;
                ${font('text3-r')};

                &:not(:last-child) {
                    margin-bottom: 12px;

                    ${mediaBreakpointUp('md')} {
                        margin-bottom: 16px;
                    }
                    ${mediaBreakpointUp('xl')} {
                        margin-bottom: 8px;
                    }
                }

                button {
                    color: ${color('white', 0.6)};
                    transition: color 0.3s ease-in-out;
                    ${hover(`color: ${color('white', 0.8)};`)};
                }
            }
        }
    }

    ${MenuList} {
        ${Spoiler} {
            span {
                color: ${color('white', 0.6)};
                transition: color 0.3s ease-in-out;
                ${hover(` color: ${color('white', 0.8)};`)};
            }
            ul {
                margin-top: 12px;
                padding-left: 20px;
            }
        }
    }
`;

export const FooterPurchases = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 28px;

    ${mediaBreakpointUp('md')} {
        margin-bottom: 0;
        margin-top: 24px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 32px;
    }

    span {
        opacity: 0.6;
        pointer-events: none;
    }
`;

export const FooterPurchasesBtn = styled.button`
    cursor: pointer;
    ${font('ui')};
    padding: 18px 16px;
    border-radius: 10px;
    white-space: nowrap;
    background-color: ${color('DeepBlue/700')};
    color: ${color('white')};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    ${hover(`
        color: ${color('text-primary')};
        background-color: ${color('white')};
    `)};

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;

export const FooterBottom = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;
    padding-top: 20px;
    color: ${color('white', 0.3)};
    ${font('text3-r')};

    ${mediaBreakpointUp('md')} {
        margin-top: 50px;
        padding-top: 25px;
    }
    ${mediaBreakpointUp('xl')} {
        margin-top: 60px;
        margin-left: auto;
        max-width: calc(100% - 450px);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        background-color: ${color('white', 0.3)};
        left: 0;
        right: 0;
    }

    a {
        align-items: flex-end;
        display: flex;
        transition: opacity 0.3s ease-in-out;

        ${hover(` opacity: 0.6`)};

        svg {
            ${allBreakpointValue('width', 110, 89, 89)};
            fill: ${color('white', 0.3)};
        }
    }
`;
