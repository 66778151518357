import { GuaranteeCalculatorType } from '@/redux/guaranteeCaclulator/type';

export const GUARANTEE_CALCULATOR_DEFAULT_VALUES: GuaranteeCalculatorType = {
    cost: 10e6,
    loanPeriod: 1,
    purchase: '0',
    guarantee: '0',
    fillingDate: null,
    guaranteeResultSum: null,
    annualPercentage: null
};
