import styled from 'styled-components';
import { buttonStyle, color, hover, offset } from '@/style/mixins';
import { ModalBtnProps } from '@/components/ui/ModalBtn/ModalBtn';

export const Container = styled.button<ModalBtnProps>`
    cursor: pointer;

    ${({ view }) =>
        view?.viewType === 'rectangle'
            ? buttonStyle(...view?.buttonParams)
            : `
            position: relative;
            background: transparent;
            padding: 0;
            outline: transparent;
        `};
    align-self: flex-start;

    &:not(:last-child) {
        ${offset('s', 'margin-right')};
    }

    &:nth-child(1) {
        color: ${({ haveBg }) => haveBg && color('white')};
        background-color: ${({ haveBg }) => haveBg && color('DeepBlue/900')};

        ${({ haveBg }) =>
            haveBg &&
            hover(`
                & {
                    background-color:${color('DeepBlue/400')};
                }  
        `)}
    }

    &:nth-child(2) {
        color: ${({ haveBg }) => haveBg && color('DeepBlue/400')};
        ${({ haveBg }) => haveBg && 'border: none;'}
        background-color: ${({ haveBg }) => haveBg && color('white')};

        ${({ haveBg }) =>
            haveBg &&
            hover(`
                & { 
                    background-color:${color('BlueGray/200')};
                }  
            `)}
    }
`;
