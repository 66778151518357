import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useIsMatches from './useIsMatches';
import Cookies from 'js-cookie';
import debounce from 'lodash.debounce';
import ResizeObserver from 'resize-observer-polyfill';

export const useWebimButtonOffset = () => {
    const { locale } = useRouter();
    const isMobile = useIsMatches('md');
    const isTablet = useIsMatches('xl');

    const setButtonStyles = (button: HTMLElement, bottom: string, right: string) => {
        if (button) {
            button.style.bottom = bottom;
            button.style.right = right;
        }
    };

    const setWebimButtonsOffsets = debounce(() => {
        const webimButtonMain = document.querySelector('a.webim_button.webim-button-slider') as HTMLElement;
        const webimButtonLoading = document.querySelector('.webim-button-loading') as HTMLElement;
        const cookieMessage = document.querySelector('.cookie-message') as HTMLElement;
        const hasCookies = !Cookies.get(`cookieMessage_${locale}`);

        const cookieMessageHeight = cookieMessage ? cookieMessage.offsetHeight : 0;

        const commonBottom = hasCookies ? (isMobile ? `${cookieMessageHeight + 12 + 32}px` : '32px') : isMobile ? '20px' : '32px';

        const commonRight = isTablet ? '20px' : '32px';

        if (hasCookies) {
            setButtonStyles(webimButtonMain, commonBottom, commonRight);
            setButtonStyles(webimButtonLoading, commonBottom, commonRight);
        } else {
            setButtonStyles(webimButtonMain, commonBottom, commonRight);
            setButtonStyles(webimButtonLoading, commonBottom, commonRight);
        }
    }, 100);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(setWebimButtonsOffsets);
        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };
    }, [setWebimButtonsOffsets]);

    return { setWebimButtonsOffsets };
};
