import styled, { css } from 'styled-components';
import { adaptiveHardValues } from '@/style/mixins';

export const Container = styled.main<{ isInner: boolean }>`
    position: relative;

    ${({ isInner }) =>
        isInner &&
        css`
            ${adaptiveHardValues('margin-top', 128, 84, 68)};
        `}
`;
