import React, { useState } from 'react';
import Search from '@/icons/new/search.svg';
import { useRouter } from 'next/router';
import { Container, HeaderSearchClear, HeaderSearchIcon, HeaderSearchInputWrapper } from './HeaderSearch.styled';
import useIsMatches from '@/hooks/useIsMatches';

interface HeaderSearchProps {
    textContent: HeaderSearchType;
}

export type HeaderSearchType = {
    input: {
        placeholder: string;
    };
};

const HeaderSearch: React.FC<HeaderSearchProps> = ({ textContent }) => {
    const isMobile = useIsMatches('md');
    const [inputValue, setInputValue] = useState('');
    const router = useRouter();
    const [isShowInput, setIsShowInput] = useState(isMobile);

    const goToSearchPage = () => {
        router.push({
            pathname: '/procedures',
            query: {
                name: inputValue
            }
        });
    };

    const onIconClickHandler = () => {
        if (!isShowInput) {
            setIsShowInput(true);
        } else if (inputValue) {
            goToSearchPage();
        }
    };

    const onClearClickHandler = () => {
        if (inputValue) {
            setInputValue('');
        } else {
            setIsShowInput(false);
        }
    };

    const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <Container className={isShowInput || isMobile ? '_show' : ''}>
            <HeaderSearchIcon onClick={onIconClickHandler}>
                <Search />
            </HeaderSearchIcon>
            <HeaderSearchInputWrapper>
                <input
                    value={inputValue}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            goToSearchPage();
                        }
                    }}
                    onChange={onChangeInputHandler}
                    placeholder={textContent.input.placeholder}
                />

                <HeaderSearchClear onClick={onClearClickHandler} className={!isMobile || inputValue.length ? '' : 'hidden'} />
            </HeaderSearchInputWrapper>
        </Container>
    );
};

export default HeaderSearch;
