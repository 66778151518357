import styled from 'styled-components';
import { adaptiveHardValues, buttonStyle, color, font, mediaBreakpointDown, mediaBreakpointUp, offset, vw } from '@/style/mixins';
import Picture from '@/components/common/Picture/Picture';
import { BaseOffsetsUI, BaseOutsideOffsetsUI } from '@/interfaces';
import { BasicBackgrounds, Colors } from '@/style/colors';
import { Container as CheckboxConfirm } from '@/components/ui/CheckboxConfirm/CheckboxConfirm.styled';
import variables, { Offsets } from '@/style/variables';
import { Container as Document, DocumentHeader, DocumentTitle, DocumentType } from '@/components/blocks/Document/Document.styled';
import { Container as InputFile } from '@/components/ui/InputFile/InputFile.styled';
import { Container as ReCaptcha } from '@/components/ui/ReCaptcha/ReCaptcha.styled';
import { Container as CircularAnimation } from '@/components/blocks/CircularAnimation/CircularAnimation.styled';

export const SubscribeFormSelectContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const FormSpoilerWrapper = styled.div<BaseOffsetsUI>`
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${SubscribeFormSelectContainer} {
        ${mediaBreakpointUp('md')} {
            width: calc(50% - 8px);
        }
    }
`;

export const Container = styled.div<
    BaseOffsetsUI &
        BaseOutsideOffsetsUI & {
            backgroundImage?: string;
            bgColor?: Colors;
            hasBg?: boolean;
            visibleOverflow?: boolean;
            textColor?: Colors;
        }
>`
    position: relative;
    overflow: ${({ visibleOverflow }) => (visibleOverflow ? 'visible' : 'hidden')};

    ${({ sideOffset }) => sideOffset && 'margin-left: 20px; margin-right: 20px;'}

    ${offset('xl', 'padding-right')};
    ${offset('xl', 'padding-left')};
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('xl', 'padding-top'))};
    ${({ bottomOffset }) => (bottomOffset ? offset(bottomOffset, 'padding-bottom') : offset('xl', 'padding-bottom'))};

    ${({ outsideTOffset }) => outsideTOffset && offset(outsideTOffset, 'margin-top')};
    ${({ outsideBOffset }) => outsideBOffset && offset(outsideBOffset, 'margin-bottom')};

    ${({ bgColor }) => (bgColor ? `background-color: ${color(bgColor, 0.9)}` : `background-color: ${color('white')}`)};
    border-radius: ${variables.corners.bigCard};

    ${({ backgroundImage }) =>
        backgroundImage &&
        `
            background-image: url(${backgroundImage});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
    `};

    ${mediaBreakpointUp('xl')} {
        margin-left: 0;
        margin-right: 0;
    }

    ${mediaBreakpointUp('xl')} {
        min-height: ${({ hasBg }) => hasBg && '400px'};
    }

    h2 {
        ${font('h1')};
    }

    & > p {
        ${font('text2-sb')};
        color: ${({ textColor }) => (textColor ? `${color(textColor)}` : `${color('text-tertiary')}`)};
    }

    p {
        &.sub-sb {
            ${font('t1-body-sb')};
        }

        &.sub-tl {
            ${font('text2-b')};
        }

        &.sub-tl2 {
            ${font('text2-b')};
        }

        &.sub-tl-big {
            ${font('h5-b')};
        }

        &.tl {
            ${font('h4-b')};
        }

        &.tl-h2 {
            ${font('h2')};
        }

        &:not(:first-of-type) {
            margin-top: 20px;
        }

        &.sb-t4 {
            ${font('text4')};
        }
    }
`;

export const SuccessMessage = styled.p`
    position: relative;
    ${offset('xl', 'margin-top')};
    ${offset('m', 'margin-bottom')};
    ${font('text1-l')};
`;

export const SendErrorMessage = styled.div`
    ${offset('m', 'margin-top')};
    ${offset('m', 'margin-bottom')};
    ${font('text1-l')};
    color: ${color('red')};
`;

export const DocsWrapper = styled.div<{ bg?: Colors; topOffset?: Offsets; bottomOffset?: Offsets }>`
    display: flex;
    flex-direction: column;
    background-color: ${({ bg }) => bg && color(bg)};
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'margin-top') : offset('l', 'margin-top'))};

    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${mediaBreakpointUp('xl')} {
        width: 451px;
    }

    & > p,
    & > span {
        ${font('t1-body-sb')};
        margin: 0;
    }

    & > div {
        ${offset('xs', 'margin-top')}

        ${Document} {
            ${DocumentHeader} {
                padding: 8px 8px 8px 12px;

                ${mediaBreakpointUp('md')} {
                    padding: 4px 4px 4px 12px;
                }
            }

            ${DocumentTitle} {
                width: 100%;
                ${font('text2-sb')}
            }

            ${DocumentType} {
                ${font('text4')}
            }

            &:not(:first-of-type) {
                ${offset('xs', 'margin-top')}
            }
        }
    }
`;

export const Bg = styled(Picture)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
`;

export const HeaderOneCol = styled.div`
    position: relative;

    h2 {
        ${mediaBreakpointUp('xl')} {
            max-width: 50%;
        }
    }
`;

export const HeaderWidthTwoCol = styled.div`
    position: relative;

    ${mediaBreakpointUp('xl')} {
        display: flex;
    }

    h2 {
        ${font('h2')}

        ${mediaBreakpointUp('xl')} {
            max-width: 446px;
        }
    }

    p {
        color: ${color('text-secondary')};
        ${offset('m', 'padding-bottom')}
        ${font('text2-sb')}

        ${mediaBreakpointDown('xl')} {
            ${offset('m', 'margin-top')}
        }

        ${mediaBreakpointUp('xl')} {
            margin-left: auto;
            margin-top: 0;
            width: 650px;
        }
    }
`;

export const BtnSubmit = styled.button`
    ${buttonStyle(false, false, 'Bg-Dark', 'text-oncolor-primary')};
    max-height: 60px;
    min-height: 40px;
    white-space: nowrap;
    flex-shrink: 0;
    align-self: flex-start;

    ${offset('m', 'margin-top')};

    ${mediaBreakpointDown('md')} {
        width: 100%;
    }

    &:disabled,
    &._disabled {
        border-color: ${color('DeepBlue/200')};
        background-color: ${color('DeepBlue/200')};
        pointer-events: none;

        span {
            opacity: 0.8;
        }
    }

    &._loading {
        span {
            opacity: 0;
        }
    }

    ${mediaBreakpointUp('fhd')} {
        ${offset('none', 'margin-top')};
    }

    ${mediaBreakpointUp('qhd')} {
        max-height: ${vw(75, 'qhd')};
    }

    ${CircularAnimation} {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg.spinner {
            ${adaptiveHardValues('width', 32, 20, 20)};
            ${adaptiveHardValues(' height', 32, 20, 20)};
        }

        svg.spinner .path {
            stroke: white;
        }
    }
`;

export const Row = styled.div<BaseOffsetsUI>`
    display: flex;
    flex-direction: column;

    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};

    &:not(:first-of-type) {
        ${({ topOffset }) => !topOffset && offset('xxxs', 'margin-top')};
    }

    ${mediaBreakpointUp('xl')} {
        flex-direction: row;
    }

    .itemForm {
        ${mediaBreakpointDown('xl')} {
            &:not(:first-child) {
                ${offset('xxxs', 'margin-top')};
            }
        }
    }

    & > ._col {
        display: flex;
        flex-direction: column;

        ${mediaBreakpointUp('xl')} {
            width: calc(50% - 8px);
        }

        & + ._col {
            ${mediaBreakpointDown('xl')} {
                ${offset('xxxs', 'margin-top')};
            }

            ${mediaBreakpointUp('xl')} {
                ${offset('xxxs', 'margin-left')};
            }
        }

        & > .itemForm {
            ${mediaBreakpointUp('xl')} {
                &:not(:first-child) {
                    ${offset('xxxxs', 'margin-top')};
                }
            }
        }
    }

    &._two-col-on-md {
        ${mediaBreakpointUp('md')} {
            flex-direction: row;
        }

        & > ._col {
            ${mediaBreakpointUp('md')} {
                width: calc(50% - 8px);
            }

            & + ._col {
                ${mediaBreakpointDown('md')} {
                    ${offset('xxxs', 'margin-top')};
                }

                ${mediaBreakpointUp('md')} {
                    ${offset('none', 'margin-top')};
                    ${offset('xs', 'margin-left')};
                }
            }

            & > .itemForm {
                ${mediaBreakpointUp('md')} {
                    &:not(:first-child) {
                        ${offset('xxxxs', 'margin-top')};
                    }
                }
            }
        }
    }

    & > ._row {
        ${mediaBreakpointUp('xl')} {
            width: calc(100% - 8px);
        }
    }
`;

export const Bottom = styled.div<{ captchaMarginBot?: boolean; isLines?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    ${offset('m', 'margin-top')};

    ${ReCaptcha} {
        ${({ captchaMarginBot }) => captchaMarginBot && offset('m', 'margin-bottom')};

        ${mediaBreakpointUp('md')} {
            ${({ captchaMarginBot }) => captchaMarginBot && 'margin-bottom: 0;'};
        }

        ${mediaBreakpointUp('xl')} {
            padding-left: 2px;
        }
    }

    ${mediaBreakpointUp('md')} {
        flex-wrap: wrap;
        flex-direction: row;
    }

    ${mediaBreakpointUp('xl')} {
        button {
            align-self: flex-end;
        }
    }

    ${({ isLines }) =>
        isLines &&
        `
    flex-direction: column;

    ${CheckboxConfirm} {
        margin-bottom: 30px;
    }
    `};
`;

export const BottomSubmit = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaBreakpointUp('md')} {
        flex-direction: row;
        align-items: flex-start;
    }

    ${BtnSubmit} {
        align-self: stretch;

        ${mediaBreakpointUp('md')} {
            align-self: flex-end;
        }
    }
`;

export const BottomRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${offset('m', 'margin-top')};

    ${mediaBreakpointUp('md')} {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;

        &._reverse {
            flex-direction: row-reverse;

            ${CheckboxConfirm} {
                margin-right: 8px;
            }
        }
    }

    ${CheckboxConfirm} {
        margin-top: 30px;

        ${mediaBreakpointUp('md')} {
            margin: 0;
            max-width: calc(50% - 4px);
        }
    }

    ${BtnSubmit} {
        ${mediaBreakpointUp('md')} {
            align-self: flex-end;
        }
    }
`;

export const FormTwoCol = styled.form<{ topOffset?: Offsets }>`
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'margin-top') : offset('l', 'margin-top'))};
    min-inline-size: auto;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border: none;

    & > .itemForm {
        & + .itemForm {
            ${offset('xxxxs', 'margin-top')};
        }
    }

    .itemForm {
        textarea {
            height: 104px;
        }
    }

    ${ReCaptcha} > div {
        ${mediaBreakpointDown('md')} {
            transform-origin: left;
            transform: scale(0.95);
        }
    }

    ${Bottom} {
        ${mediaBreakpointUp('xl')} {
            width: calc(50% - 4px);
        }
    }
`;

export const CheckboxWrapper = styled.div`
    max-height: 320px;
    overflow-y: auto;

    ${mediaBreakpointUp('xl')} {
        max-height: 520px;
    }
`;

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${offset('xl', 'margin-top')};

    ${mediaBreakpointUp('md')} {
        justify-content: space-between;
        flex-direction: row;
    }

    ${BtnSubmit} {
        order: 1;

        ${mediaBreakpointUp('md')} {
            align-self: flex-end;
            width: fit-content;
            order: 0;
            margin-top: 0;
        }
    }

    ${InputFile} {
        ${mediaBreakpointUp('md')} {
            max-width: calc(50% - 4px);
        }
    }

    & > ${Bottom} {
        ${offset('none', 'margin-top')};
    }
`;

export const StepFormContainer = styled.form<BaseOffsetsUI & BaseOutsideOffsetsUI & { bgColor?: BasicBackgrounds }>`
    position: relative;
    ${({ topOffset }) => (topOffset ? offset(topOffset, 'padding-top') : offset('main', 'padding-top'))};
    ${({ bottomOffset }) => (bottomOffset ? offset(bottomOffset, 'padding-bottom') : offset('main', 'padding-bottom'))};

    ${({ sideOffset }) => sideOffset && 'margin-left: 20px; margin-right: 20px;'}

    ${HeaderWidthTwoCol} {
        ${offset('xl', 'padding-left')};
        ${offset('xl', 'padding-right')};
    }

    ${mediaBreakpointUp('xl')} {
        margin-left: 0;
        margin-right: 0;
    }

    h2 {
        width: 100%;
        ${font('h2')};
    }

    p {
        ${font('text2-sb')};
    }
`;

export const StepFormWrapper = styled.div<BaseOffsetsUI & BaseOutsideOffsetsUI & { bgColor?: BasicBackgrounds }>`
    position: relative;
    border-radius: ${variables.corners.bigCard};
    ${({ bgColor }) => `background-color: ${color(bgColor ? bgColor : 'white')};`}
    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
`;

export const StepFormSection = styled.div<BaseOffsetsUI & { line?: boolean }>`
    position: relative;

    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};

    ${({ line }) =>
        line &&
        `border-top: thin solid ${color('black', 0.1)};

    ${offset('l', 'padding-top')}`};
`;

export const Spacer = styled.div<BaseOffsetsUI>`
    position: relative;

    ${({ topOffset }) => topOffset && offset(topOffset, 'margin-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'margin-bottom')};
`;

export const Buttons = styled.div`
    display: flex;

    ${mediaBreakpointUp('md')} {
        ${offset('xs', 'margin-top')};
    }

    ${mediaBreakpointDown('md')} {
        flex-direction: column;
    }

    button {
        ${buttonStyle(false, false, 'black', 'white')};
        max-height: 60px;
        min-height: 40px;
        white-space: nowrap;
        flex-shrink: 0;

        ${offset('m', 'margin-top')};

        &:not(:first-child) {
            ${offset('xxxxs', 'margin-left')};

            ${mediaBreakpointDown('md')} {
                margin-left: 0;
                margin-top: 6px;
            }
        }

        ${mediaBreakpointDown('md')} {
            margin-left: 0;
        }

        &:disabled,
        &._disabled {
            background-color: ${color('DeepBlue/200')};
            pointer-events: none;

            span {
                opacity: 0.4;
            }
        }

        &._loading {
            span {
                opacity: 0;
            }
        }

        ${CircularAnimation} {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            svg.spinner {
                ${adaptiveHardValues('width', 32, 20, 20)};
                ${adaptiveHardValues(' height', 32, 20, 20)};
            }

            svg.spinner .path {
                stroke: white;
            }
        }
    }
`;
